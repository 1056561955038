import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { Heading } from '../Heading';
import { Tag } from '.';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tag"
    }}>{`Tag`}</h1>
    <p>{`The Tag is used to list themes or keywords related to an experience or blog post.
It can be used by itself, or in combination with a `}<a parentName="p" {...{
        "href": "/components/Heading"
      }}>{`Heading`}</a>{`. If used before a Heading, it behaves the same as `}<a parentName="p" {...{
        "href": "/components/Caption"
      }}>{`Caption`}</a>{`. It nests itself on top of the Heading to give context.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Tag} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <Playground __position={1} __code={'<Tag>Featured</Tag>\n<Tag>Exciting</Tag>\n<Tag>New</Tag>\n<Heading as=\"h3\">The hop of your life</Heading>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Heading,
      Tag,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Tag mdxType="Tag">Featured</Tag>
  <Tag mdxType="Tag">Exciting</Tag>
  <Tag mdxType="Tag">New</Tag>
  <Heading as="h3" mdxType="Heading">The hop of your life</Heading>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      